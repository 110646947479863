import styled from 'styled-components'

export default styled.div`
  font-family: 'Prompt', 'sans-serif';
  font-size: 12px;
  font-weight: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: white;
  margin-bottom: 10px;
  display: inline-block;
  &.label-material {
    font-family: 'Prompt-SemiBold', sans-serif;
    color: rgba(31, 45, 57, 0.3);
    font-size: 14px;
    margin: 0px;
  }
`
