import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import LabelWrapper from './styledComponent'

export default class LabelInput extends PureComponent {
  render() {
    let className = 'label-wrapper'
    if (this.props.className) {
      className += ` ${this.props.className}`
    }
    return <LabelWrapper className={className}>{this.props.text}</LabelWrapper>
  }
}

LabelInput.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string
}
